:root {
  --bs-primary-rgb: #FFFEE5;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-form-item-explain-error, label {
  font-size: 12px;
  opacity: .7;
}

.standard-width {
  width: 85%;
}

.mrz-bg-primary {
  background-color: #FFFEE5;
}

.sign-in-container {
  background-color: #3B7779;
  height: 35%;
  padding: 40px 0px;
  margin: auto;
  text-align: center;
}

input:not(:last-child) {
  margin-bottom: 8px;
}

.user-details-wrapper {
  margin-top: 4px;
}
.user-details-wrapper p {
  margin-bottom: 0;
  font-size: 11px;
  font-weight: 500;
}
.user-details-wrapper h6 {
  font-size: 0.85rem;
}
.user-details-wrapper .row {
  margin-bottom: 4px;
}

.signin-input {
  background-color: transparent;
  border: none;
  border-radius: 0;
  border-bottom: solid rgba(0, 0, 0, .1);
}

.text-4xs {
  font-size: 0.55rem;
}

.text-3xs {
  font-size: 0.6rem;
}

.text-2xs {
  font-size: 0.7rem;
}

.text-xs {
  font-size: 0.8rem;
}

.text-sm {
  font-size: 0.9rem;
}